/* styles.css */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

.custom-calendar-style {
  border-radius: 8px;
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  padding: 4px;
}

.atua-date-time-picker-style {
  .react-datepicker {
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    z-index: 50;
  }

  .react-datepicker-popper {
    display: flex;
    justify-content: flex-start;
  }

  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
    padding: 20px 20px 8px 20px;
  }

  .react-datepicker-time__header,
  .react-datepicker__current-month {
    color: #3e484e;
    font-weight: medium;
    font-size: 12px;
  }

  .react-datepicker__navigation {
    padding: 24px 16px 8px 16px;
  }

  .react-datepicker__day-names {
    margin-bottom: -10px;
  }

  .react-datepicker__day-name {
    color: #899498;
    margin: 6px;
  }
  .react-datepicker__day {
    margin: 2px 6px;
    font-size: 12px;
  }

  .react-datepicker__day {
    border-radius: 100%;
    &:hover {
      border-radius: 100%;
    }
  }
  .react-datepicker__time {
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #8b5cf6;
    color: white;
    font-weight: bold;
  }
}
.selected-hour {
  background-color: #8b5cf6; /* Cambia el color de fondo del valor seleccionado */
  color: #fff; /* Cambia el color del texto */
}

.selected-day {
  background-color: #8b5cf6; /* Cambia el color de fondo */
  color: #ffffff; /* Cambia el color del texto */
  &:hover {
    background-color: darken(#8b5cf6, 5%);
  }
}
